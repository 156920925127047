import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faCopyright, faEnvelope, faHeart } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import logo from '../images/logo-white.png'

class MailchimpSignupComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      isLoading: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  onSubmit (event) {
    event.preventDefault()
    this.setState({
      isLoading: true
    })
    addToMailchimp(this.state.email)
      .then(data => {
        this.setState({
          isLoading: false,
        })
        if (data.result === 'success') {
          this.setState({
            email: '',
            isComplete: true,
            errorMessage: null
          })
        } else {
          this.setState({
            isComplete: false,
            errorMessage: data.msg
          })
        }
      })
      .catch(() => {})
  }

  render () {
    let loadingClass = (this.state.isLoading) ? 'd-none' : ''

    let completeClass = (this.state.isComplete) ? 'd-none' : ''

    let placeholderText = (this.state.isComplete) ? 'Thanks for signing up!' : 'Stay in touch, enter your email!'

    let errorMessage = (this.state.errorMessage) ? (<small id="emailHelp" className="form-text text-center" dangerouslySetInnerHTML={{ __html: this.state.errorMessage }} />) : (<></>)

    let input

    if (this.state.isComplete) {
      input = (<input type="text" className="form-control" id="email" name="email" autocomplete placeholder={placeholderText} value={this.state.email} onChange={this.handleChange} readOnly />)
    } else {
      input = (<input type="text" className="form-control" id="email" name="email" autocomplete placeholder={placeholderText} value={this.state.email} onChange={this.handleChange} />)
    }

    return (
      <form autoComplete="off" onSubmit={this.onSubmit} >
        <div className={`input-group`}>
          { input }
          <div className={`input-group-append`}>
            <span className="input-group-text">
              <button onClick={this.onSubmit} className={`btn btn-link p-0 m-0 ${ loadingClass } ${ completeClass }`} >
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </button>
            </span>
          </div>
        </div>
        { errorMessage }
      </form>
    )
  }
}

const Footer = () => (
  <footer>
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6 pb-3">
          <div className="logo-container">
            <div className="logo-contents">
              <a href="/"><img src={ logo } id="logo" alt="Simple Summers Logo" style={{ maxHeight: '4rem' }} /></a>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div><a href="/about-us/">About Us</a></div>
          <div><a href="/search/">All Camps</a></div>
        </div>
        <div className="col-md-2">
          <div><a href="/privacy/">Privacy Policy</a></div>
          <div><a href="/terms/">Terms of Service</a></div>
        </div>
        <div className="col-md-2 pb-3">
          <div><a href="/contact-us/">Contact Us</a></div>
          <div>
            <a href="mailto:hello@simplesummers.com"><FontAwesomeIcon icon={faEnvelope} /></a>
            <a href="https://www.instagram.com/simplesummersIG/" className="pl-2" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://www.facebook.com/simplesummers/" className="pl-2"><FontAwesomeIcon icon={faFacebook} target="_blank" rel="noopener noreferrer" /></a>
          </div>
        </div>
      </div>
      <div className="offset-md-3 col-md-6 py-3" id="signup">
        <MailchimpSignupComponent />
      </div>
      <div className="row align-items-end">
        <div className="col-md-12 legal text-center pt-3">
          Copyright <FontAwesomeIcon icon={faCopyright} /> 2018-{ new Date().getFullYear() } Simple Summers, LLC.  All rights reserved.  <br className="d-none d-lg-block"/>
          Made with <FontAwesomeIcon icon={faHeart} /> in Sacramento, California.
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
