import '../../node_modules/react-toastify/dist/ReactToastify.css'
import './styles.scss'
import { Slide, ToastContainer } from 'react-toastify'
import { StaticQuery, graphql } from 'gatsby'
import Footer from './footer'
import Header from './header'
import React from 'react'
import ReactGA from 'react-ga4'
// import ReactGA from 'react-ga4'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag('9b7686bcf0af58418fe740cdc0b847b1')
bugsnagClient.use(bugsnagReact, React)

// ReactGA.initialize('G-58H4HW3RVD')
// ReactGA.initialize('UA-131454185-2')
ReactGA.initialize('G-MG6QMFCLBJ')

const ErrorBoundary = bugsnagClient.getPlugin('react')

const Layout = ({ children, location }) => {
  // ReactGA.pageview(location.pathname)

  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
      <>
        <ErrorBoundary>
          <ToastContainer transition={Slide} />
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>
            {children}
          </main>
          <Footer />
        </ErrorBoundary>
      </>
      )}
    />
  )
}

export default Layout
