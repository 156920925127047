import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSearch, faUserCircle, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { globalHistory } from '@reach/router'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import logo from '../images/logo-energetic-blue.png'

class Header extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      user: null
    }
  }

  componentDidMount = async () => {
    Auth.currentAuthenticatedUser().then(response => {
      this.setState({ user: response })
    }).catch(err => {
      console.log(err)
      this.setState({ user: null })
    })
  }

  render () {

    const aboutButton = (() => {
      const isSelected = (globalHistory.location.pathname === '/about-us/')
      const buttonClass = `btn btn-light btn-sm ml-3 d-none d-md-block ${ (isSelected) ? "active" : "" }`
        return (
          <a href="/about-us/">
            <button type="button" className={ buttonClass } >
              Meet Simple Summers
            </button>
            <button type="button" className='btn btn-link btn-sm ml-3 d-block d-md-none'>
              <FontAwesomeIcon icon={ faCoffee } size="lg" />
            </button>
          </a>
        )
    })()

      const searchButton = (() => {
        const isSelected = (globalHistory.location.pathname === '/search/')
        const buttonClass = `btn btn-light btn-sm ml-3 d-none d-md-block ${ (isSelected) ? "active" : "" }`
          return (
            <a href="/search/">
              <button type="button" className={ buttonClass }>
                Search All Camps
              </button>
              <button type="button" className='btn btn-link btn-sm ml-3 d-block d-md-none'>
                <FontAwesomeIcon icon={ faSearch } size="lg" />
              </button>
            </a>
          )
      })()

    const accountButton = (() => {
      const isSelected = (globalHistory.location.pathname === '/account/')
      const buttonClass = `btn btn-cta btn-sm ml-3 d-none d-md-block ${ (isSelected) ? "active" : "" }`
        return (
          <a href="/account/">
            <button type="button" className={ buttonClass }>
              { this.state.user ? 'My Account' : 'Sign In or Sign Up' }
            </button>
            <button type="button" className='btn btn-link btn-sm ml-3 d-block d-md-none'>
              <FontAwesomeIcon icon={ faUserCircle } size="lg" />
            </button>
          </a>
        )
    })()

    const alertBar = ( data ) => {
        if ( data.contentfulAlertBar.active ) {
            if ( data.contentfulAlertBar.link ) {
                return (
                    <div className={ `alert-bar ${ data.contentfulAlertBar.color }` }>
                        <a href={data.contentfulAlertBar.link}><span>{data.contentfulAlertBar.content}</span></a>
                    </div>
                )
            } else {
                return (
                    <div className={ `alert-bar ${ data.contentfulAlertBar.color }` }>
                        <span>{data.contentfulAlertBar.content}</span>
                    </div>
                )
            }
        }
    }

    return (
      <header>
        <StaticQuery
        query={graphql`
            query HeadingQuery {
              contentfulAlertBar {
                active
                content
                link
                color
              }
            }
        `}
        render={ data => (
            alertBar(data)
        )}
        />
        <div className="container py-5">
          <div className="d-flex">
            <div className="flex-grow-1">
              <a href="/">
                <img src={ logo } id="logo" alt="Simple Summers Logo" style={{ maxHeight: '4rem' }} />
              </a>
            </div>
            <div>
              { aboutButton }
            </div>
            <div>
              { searchButton }
            </div>
            <div>
              { accountButton }
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
